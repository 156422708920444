import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { fetchsingleproduct, addToCart, submitReview } from '../../Api/productApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { GlassMagnifier } from 'react-image-magnifiers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RelatedProducts from './RelatedProducts';
import useAuth from '../../Hooks/useAuth';
import Rating from 'react-rating';
import { BASE_IMAGE_URL } from '../apiConfig';

const Productdetails = () => {
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewDescription, setReviewDescription] = useState('');
    const [rating, setRating] = useState(0);
    const { productSlug } = useParams();
    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [selectedThumbnail, setSelectedThumbnail] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [isAddedToQuote, setIsAddedToQuote] = useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const { isAuthenticated, userDetails } = useAuth();
    const token = localStorage.getItem('userToken');
    const customerId = userDetails?.id;
    const navigate = useNavigate();

    const handleRatingChange = (rate) => {
        setRating(rate);
    };

    const handleToggleForm = () => {
        setShowForm(prev => !prev);
    };

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const fetchedProduct = await fetchsingleproduct(productSlug, token);
                console.log('details', fetchedProduct);

                setProduct(fetchedProduct);
                if (fetchedProduct && fetchedProduct.images.length > 0) {
                    setMainImage(`${BASE_IMAGE_URL}/${fetchedProduct.primary_image}`);
                    setSelectedThumbnail(`${BASE_IMAGE_URL}/${fetchedProduct.primary_image}`);
                }
                const quotes = JSON.parse(localStorage.getItem('quoteRequests')) || [];
                const existingQuote = quotes.find(q => q.id === fetchedProduct.id);
                if (existingQuote) {
                    setIsAddedToQuote(true);
                }
                if (fetchedProduct.is_in_cart) {
                    setIsAddedToCart(true);
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProductData();
    }, [productSlug, token]);

    const handleThumbnailClick = (imageSrc) => {
        setMainImage(imageSrc);
        setSelectedThumbnail(imageSrc);
    };


    const handleRequestQuote = () => {
        const customRate = product.custom_rates && product.custom_rates.length > 0 ? product.custom_rates[0].custom_rate : null;
        const price = customRate || product.discount_price || product.base_price;

        const quoteRequest = {
            id: product.id,
            name: product.product_name,
            quantity,
            price,
            customRate,
        };

        let quotes = JSON.parse(localStorage.getItem('quoteRequests')) || [];
        const existingQuote = quotes.find(q => q.id === quoteRequest.id);

        if (!existingQuote) {
            quotes.push(quoteRequest);
            localStorage.setItem('quoteRequests', JSON.stringify(quotes));
            setIsAddedToQuote(true);
            toast.success('Successfully added to quote list');
        } else {
            toast.info('Product is already in the quote list');
        }
    };

    const handleAddToCart = async () => {
        if (!isAuthenticated) {
            toast.error('You must be logged in to add to cart.');
            setTimeout(() => {
                navigate('/signin');
            }, 1000);
            return;
        }

        const cartItem = {
            product_id: product.id,
            quantity
        };

        try {
            await addToCart(cartItem, token);
            setIsAddedToCart(true);
            toast.success('Product added to cart successfully');
            navigate('/cart');
        } catch (error) {
            toast.error('Failed to add product to cart.');
        }
    };

    const handleSubmitRequest = () => {
        navigate('/quote-request');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isAuthenticated) {
            toast.error('You must be logged in to submit a review.');
            return;
        }

        const reviewData = {
            product_id: product.id,
            customer_id: customerId,
            rating,
            review_title: reviewTitle,
            review_description: reviewDescription
        };

        try {
            await submitReview(reviewData, token);
            toast.success('Review submitted successfully!');
            setReviewTitle('');
            setReviewDescription('');
            setRating(0);
            setShowForm(false);
        } catch (error) {
            toast.error('An error occurred while submitting the review.');
        }
    };
    const generateStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;

        return (
            <>
                {[...Array(fullStars)].map((_, index) => (
                    <i key={`full-${index}`} className="bi bi-star-fill text-warning" />
                ))}
                {halfStar === 1 && <i className="bi bi-star-half text-warning" />}
                {[...Array(emptyStars)].map((_, index) => (
                    <i key={`empty-${index}`} className="bi bi-star text-warning" />
                ))}
            </>
        );
    };
    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Header />
            <main>
                <section className="mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-xl-6">
                                <div className="product" id="product">
                                    <GlassMagnifier
                                        imageSrc={mainImage}
                                        imageAlt={product.product_name}
                                        largeImageSrc={mainImage}
                                    />
                                </div>

                                <div className="product-tools">
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        pagination={{ clickable: true }}
                                    >
                                        <SwiperSlide key="primary">
                                            <img
                                                src={`${BASE_IMAGE_URL}/${product.primary_image}`}
                                                alt="Primary"
                                                onClick={() => handleThumbnailClick(`${BASE_IMAGE_URL}/${product.primary_image}`)}
                                                className={`thumbnail ${selectedThumbnail === `${BASE_IMAGE_URL}/${product.primary_image}` ? 'active' : ''}`}
                                            />
                                        </SwiperSlide>
                                        {product.images.map((img, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={`${BASE_IMAGE_URL}/${img.images}`}
                                                    alt={`Thumbnail ${index}`}
                                                    onClick={() => handleThumbnailClick(`${BASE_IMAGE_URL}/${img.images}`)}
                                                    className={`thumbnail ${selectedThumbnail === `${BASE_IMAGE_URL}/${img.images}` ? 'active' : ''}`}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>

                            <div className="col-md-7 col-xl-6">
                                <div className="ps-lg-10 mt-6 mt-md-0">
                                    <a href="#!" className="mb-4 d-block">{product.category.title}</a>
                                    <h1 className="mb-1">{product.product_name}</h1>
                                    {product.average_rating >= 3 ? (
                                        <div className="mb-4">
                                            <small className="text-warning">
                                                {generateStars(product.average_rating)}
                                            </small>
                                            <a href="#" className="ms-2">({product.average_rating} reviews)</a>
                                        </div>
                                    ) : (
                                        <p></p>
                                    )}
                                    {isAuthenticated ? (
                                        <>
                                            <div className="fs-4">
                                                {product.custom_rates && product.custom_rates.length > 0 ? (
                                                    <div className="mt-1">
                                                        <p className='text-small' style={{ fontSize: '0.7rem' }}>(This Price Only for you By Price List)</p>
                                                        <span className='text-dark'>Price: ₹{product.custom_rates[0].custom_rate}</span>
                                                        <span className="text-decoration-line-through text-muted ms-2">
                                                            ₹{product.discount_price}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="mt-1">
                                                        <span className="fw-bold text-dark">
                                                            ₹{product.discount_price || product.base_price}
                                                        </span>
                                                        {product.discount_price && product.discount_price < product.base_price && (
                                                            <>
                                                                <span className="text-decoration-line-through text-muted ms-2">
                                                                    ₹{product.base_price}
                                                                </span>

                                                            </>
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                            <hr className="my-6" />

                                            <div>
                                                <p style={{ color: product.stock_quantity > 0 ? 'green' : 'red' }}>
                                                    {product.stock_quantity > 0 ? `In stock (${product.stock_quantity})` : 'Out of stock'}
                                                </p>
                                                <div className="button-group d-flex gap-3">
                                                    {!isAddedToCart ? (
                                                        <button
                                                            type="button"
                                                            className={`btn w-100 ${product.stock_quantity === 0 ? 'btn-secondary' : 'btn-primary'}`}
                                                            onClick={handleAddToCart}
                                                            disabled={product.stock_quantity === 0}
                                                        >
                                                            {product.stock_quantity === 0 ? 'Out of Stock' : 'Add to Cart'}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100"
                                                            onClick={() => navigate('/cart')}
                                                        >
                                                            Go to Cart
                                                        </button>
                                                    )}

                                                    {!isAddedToQuote && (
                                                        <button
                                                            className="btn btn-outline-primary w-100"
                                                            onClick={handleRequestQuote}
                                                        >
                                                            Add to Quote List
                                                        </button>
                                                    )}

                                                    {isAddedToQuote && (
                                                        <button
                                                            className="btn btn-outline-primary w-100"
                                                            onClick={handleSubmitRequest}
                                                        >
                                                            View Quote List
                                                        </button>
                                                    )}
                                                </div>
                                            </div>

                                            <hr className="my-6" />

                                        </>
                                    ) : (
                                        <p>Please log in to view details and add to cart.</p>
                                    )}
                                </div>

                                <hr className="my-6" />
                                <div>
                                    <table className="table table-borderless mb-0">
                                        <tbody>
                                            <tr>
                                                <td>Product Code:</td>
                                                <td>{product.sku}</td>
                                            </tr>
                                            <tr>
                                                <td>Model Number:</td>
                                                <td>{product.model_number}</td>
                                            </tr>
                                            <tr>
                                                <td>Brand:</td>
                                                <td>{product.brand}</td>
                                            </tr>
                                            <tr>
                                                <td>Shipping:</td>
                                                <td>
                                                    <small>
                                                        01 day shipping.
                                                        <span className="text-muted">( Free pickup today)</span>
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-8">
                                    <div className="dropdown">
                                        <a className="btn btn-outline-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Share</a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-facebook me-2" />
                                                    Facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-twitter me-2" />
                                                    Twitter
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-instagram me-2" />
                                                    Instagram
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="mt-lg-14 mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="nav nav-pills nav-lb-tab" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="product-tab" data-bs-toggle="tab" data-bs-target="#product-tab-pane" type="button" role="tab" aria-controls="product-tab-pane" aria-selected="true">
                                            DESCRIPTION
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details-tab-pane" type="button" role="tab" aria-controls="details-tab-pane" aria-selected="false">
                                            SPECIFICATION
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">
                                            REVIEWS
                                        </button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="product-tab-pane" role="tabpanel" aria-labelledby="product-tab" tabIndex={0}>
                                        <p dangerouslySetInnerHTML={{ __html: product.long_description }} className='mt-4' />
                                    </div>
                                    <div className="tab-pane fade" id="details-tab-pane" role="tabpanel" aria-labelledby="details-tab" tabIndex={0}>
                                        <div className="my-8">
                                            {product.specification ? (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="mb-4">SPECIFICATION</h4>
                                                    </div>
                                                    <div className="col-12 col-lg-12">
                                                        <table className="table table-striped">
                                                            <tbody>
                                                                <>
                                                                    {product.specification.map((spec) => (
                                                                        <tr key={spec.id}>
                                                                            <th>{spec.title}</th>
                                                                            <td>{spec.description}</td>
                                                                        </tr>
                                                                    ))}
                                                                </>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="mt-4">No SPECIFICATION Found</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabIndex={0}>
                                        <div className="my-8">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="me-lg-12 mb-6 mb-md-0">
                                                        <div className="mb-5">
                                                            <h4 className="mb-3">Customer reviews</h4>
                                                            <span>
                                                                <small className="text-warning">
                                                                    <i className="bi bi-star-fill" />
                                                                    <i className="bi bi-star-fill" />
                                                                    <i className="bi bi-star-fill" />
                                                                    <i className="bi bi-star-fill" />
                                                                    <i className="bi bi-star-half" />
                                                                </small>
                                                                <span className="ms-3">4.1 out of 5</span>
                                                                <small className="ms-3">11,130 global ratings</small>
                                                            </span>
                                                        </div>
                                                        <div className="mb-8">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="text-nowrap me-3 text-muted">
                                                                    <span className="d-inline-block align-middle text-muted">5</span>
                                                                    <i className="bi bi-star-fill ms-1 small text-warning" />
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="progress" style={{ height: 6 }}>
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '60%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} />
                                                                    </div>
                                                                </div>
                                                                <span className="text-muted ms-3">53%</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="text-nowrap me-3 text-muted">
                                                                    <span className="d-inline-block align-middle text-muted">4</span>
                                                                    <i className="bi bi-star-fill ms-1 small text-warning" />
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="progress" style={{ height: 6 }}>
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={50} />
                                                                    </div>
                                                                </div>
                                                                <span className="text-muted ms-3">22%</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="text-nowrap me-3 text-muted">
                                                                    <span className="d-inline-block align-middle text-muted">3</span>
                                                                    <i className="bi bi-star-fill ms-1 small text-warning" />
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="progress" style={{ height: 6 }}>
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '35%' }} aria-valuenow={35} aria-valuemin={0} aria-valuemax={35} />
                                                                    </div>
                                                                </div>
                                                                <span className="text-muted ms-3">14%</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="text-nowrap me-3 text-muted">
                                                                    <span className="d-inline-block align-middle text-muted">2</span>
                                                                    <i className="bi bi-star-fill ms-1 small text-warning" />
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="progress" style={{ height: 6 }}>
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '22%' }} aria-valuenow={22} aria-valuemin={0} aria-valuemax={22} />
                                                                    </div>
                                                                </div>
                                                                <span className="text-muted ms-3">5%</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="text-nowrap me-3 text-muted">
                                                                    <span className="d-inline-block align-middle text-muted">1</span>
                                                                    <i className="bi bi-star-fill ms-1 small text-warning" />
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="progress" style={{ height: 6 }}>
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '14%' }} aria-valuenow={14} aria-valuemin={0} aria-valuemax={14} />
                                                                    </div>
                                                                </div>
                                                                <span className="text-muted ms-3">7%</span>
                                                            </div>
                                                        </div>

                                                        {isAuthenticated ? (
                                                            <>
                                                                <button onClick={handleToggleForm} className="btn btn-outline-gray-400 mt-4 text-muted">
                                                                    {showForm ? 'Cancel Review' : 'Write the Review'}
                                                                </button>
                                                                {showForm && (
                                                                    <div>
                                                                        <h4 className="mb-4 mt-5">Submit Your Review</h4>
                                                                        <form onSubmit={handleSubmit}>
                                                                            <div className="mb-3">
                                                                                <label htmlFor="reviewTitle" className="form-label">Review Title</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="reviewTitle"
                                                                                    value={reviewTitle}
                                                                                    onChange={(e) => setReviewTitle(e.target.value)}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label htmlFor="reviewDescription" className="form-label">Review Description</label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="reviewDescription"
                                                                                    value={reviewDescription}
                                                                                    onChange={(e) => setReviewDescription(e.target.value)}
                                                                                    rows="3"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label htmlFor="rating" className="form-label me-2">Rating</label>
                                                                                <Rating
                                                                                    initialRating={rating}
                                                                                    fractions={2}
                                                                                    onChange={handleRatingChange}
                                                                                    emptySymbol="bi bi-star"
                                                                                    fullSymbol="bi bi-star-fill text-warning"
                                                                                    className='fs-5'
                                                                                />
                                                                                <input type="hidden" id="rating" value={rating} />
                                                                            </div>
                                                                            <button type="submit" className="btn btn-primary">Submit Review</button>
                                                                        </form>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <p className="mt-4">Please <a href="/login">log in</a> to write a review.</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="mb-10">
                                                        <div className="d-flex justify-content-between align-items-center mb-8">
                                                            <div>
                                                                <h4>Reviews</h4>
                                                            </div>
                                                            <div>
                                                                <select className="form-select">
                                                                    <option defaultValue>Top Reviews</option>
                                                                    <option value="Most Recent">Most Recent</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {product.ratings.map(rating => (
                                                            <div className="d-flex border-bottom pb-6 mb-6 pt-4">
                                                                <img src="../assets/images/b2b/user.png" alt='image' className="rounded-circle avatar-lg" />
                                                                <div className="ms-5">
                                                                    <h6 className="mb-1">{rating.customer.primary_contact_name}</h6>
                                                                    <p className="small">
                                                                        <span className="text-muted">29 December 2022</span>
                                                                        {/* <span className="text-primary ms-3 fw-bold">Verified Purchase</span> */}
                                                                    </p>
                                                                    <div className="mb-2">
                                                                        <i className="bi bi-star-fill text-warning" />
                                                                        <i className="bi bi-star-fill text-warning" />
                                                                        <i className="bi bi-star-fill text-warning" />
                                                                        <i className="bi bi-star-fill text-warning" />
                                                                        <i className="bi bi-star text-warning" />
                                                                        <span className="ms-3 text-dark fw-bold">{rating.review_title}</span>
                                                                    </div>
                                                                    <p>
                                                                        {rating.review_description}
                                                                    </p>
                                                                    <div className="d-flex justify-content-end mt-4">
                                                                        <a href="#" className="text-muted">
                                                                            <i className="feather-icon icon-thumbs-up me-1" />
                                                                            Helpful
                                                                        </a>
                                                                        <a href="#" className="text-muted ms-4">
                                                                            <i className="feather-icon icon-flag me-2" />
                                                                            Report abuse
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div>
                                                            <a href="#" className="btn btn-outline-gray-400 text-muted">Read More Reviews</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <RelatedProducts></RelatedProducts>
            </main>
            <Footer />
            <ToastContainer />      
        </>
    );
};

export default Productdetails;