import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerSuccess, registerFailure } from '../../reducers/authSlice';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apiConfig';

import 'react-toastify/dist/ReactToastify.css';

export default function Signup() {
    const [formData, setFormData] = useState({
        // tax_name: '',
        gstno: '',
        company_name: '',
        first_name: '',
        last_name: '',
        phone: '',
        fax: '',
        email: '',
        password: '',
        type_of_company: '',
        no_of_employee: '',
        s_address_line1: '',
        s_address_line2: '',
        s_city: '',
        s_state: '',
        s_pincode: '',
        s_country: '',
        b_address_line1: '',
        b_address_line2: '',
        b_city: '',
        b_state: '',
        b_pincode: '',
        b_country: '',
    });

    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');
    const [responseError, setResponseError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.auth);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleGSTABNChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            tax_name: value,
            gst_number: '',
            abn_number: '',
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/customer-register`, formData);
            const { data } = response;

            if (data.status) {
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('userDetails', JSON.stringify(data.customer));
                localStorage.setItem('isAuthenticated', 'true');
                dispatch(registerSuccess({
                    token: data.token,
                    customer: data.customer,
                }));

                setResponseMessage(data.message);
                setResponseError('');
                toast.success(data.message || 'Register Successfully');

                setTimeout(() => {
                    navigate('/');
                }, 1000);
            } else {
                setErrors(data.error);
                dispatch(registerFailure({ error: data.error }));
                setResponseMessage(data.message);
                setResponseError(`Error: ${data.message}`);
                toast.error(data.message);
            }
        } catch (error) {
            setErrors(error.response.data.error);
            dispatch(registerFailure({ error: error.response.data.error }));
            setResponseMessage(error.response.data.error);
            setResponseError("An unexpected error occurred.");
            toast.error("An unexpected error occurred.");
        }
    };
    const maxLength = 30;
    return (
        <>
            <Header />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-12 col-lg-12 order-lg-2 order-1">
                                <div className="mb-lg-9 mb-5 text-center">
                                    <h1 className="mb-1 h2 fw-bold">Signup to <span className="text-success fw-bold">V2UMart</span></h1>
                                    <p>Thanks for your interest in our products. Please fill out the form below to register for a b2b account. Approved accounts will have access to our online order system. If you are an existing b2b customer and need to place an order with us, please login.</p>
                                </div>
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row g-3">
                                                <h1 className="mb-1 h2 fw-bold">Company Information</h1>
                                                <hr />
                                            </div>
                                            <div className="row g-3">
                                                {/* <div className="col-lg-4 col-sm-12">
                                                    <label className="form-label">
                                                        Select GST or ABN
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select id="tax_name" name="tax_name" className="form-control" value={formData.tax_name} onChange={handleGSTABNChange} required>
                                                        <option value="">select</option>
                                                        <option value="gst">GST</option>
                                                        <option value="abn">ABN</option>
                                                    </select>
                                                </div> */}
                                                <div className="col-lg-12 col-sm-12">
                                                    <label className="form-label">
                                                        Enter GST 
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                  
                                                        <input type="text" id="gstno" name="gstno" className={`form-control ${errors.gstno ? 'is-invalid' : ''}`} placeholder="GST Number" value={formData.gstno} onChange={handleChange} required />
                                                   
                                                    {errors.gstno && <div className="invalid-feedback">{errors.gstno[0]}</div>}
                                                </div>

                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">
                                                        Company Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text" className={`form-control ${errors.company_name ? 'is-invalid' : ''}`} id="company_name" name="company_name" placeholder="Company Name" value={formData.company_name} onChange={handleChange} required />
                                                    {errors.company_name && <div className="invalid-feedback">{errors.company_name[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">
                                                        Company Website Domain
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text" className={`form-control ${errors.company_domain ? 'is-invalid' : ''}`} id="company_domain" name="company_domain" placeholder="Company Website Domain" value={formData.company_domain} onChange={handleChange} required />
                                                    {errors.company_domain && <div className="invalid-feedback">{errors.company_domain[0]}</div>}
                                                </div>

                                                {/* <div className="col-12">
                                                    <label className="form-label">
                                                        Authorized  Signature
                                                        <span className="text-danger">*</span>
                                                    </label>

                                                    <input type="file" id="auth_sign" name="auth_sign" className={`form-control ${errors.auth_sign ? 'is-invalid' : ''}`} onChange={handleChange} required />
                                                    {errors.auth_sign && <div className="invalid-feedback">{errors.auth_sign[0]}</div>}
                                                </div> */}
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">
                                                        Type Of Company
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input type="text" className={`form-control ${errors.type_of_company ? 'is-invalid' : ''}`} id="type_of_company" name="type_of_company" placeholder="Type Of Company" value={formData.type_of_company} onChange={handleChange} required />
                                                    {errors.type_of_company && <div className="invalid-feedback">{errors.type_of_company[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">
                                                        Select Number of Employees
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select className={`form-control ${errors.no_of_employee ? 'is-invalid' : ''}`} name="no_of_employee" id="no_of_employee" value={formData.no_of_employee} onChange={handleChange} required>
                                                        <option value="">Select Here</option>
                                                        <option value="1-100">Between 1 to 100</option>
                                                        <option value="101-500">Between 101 to 500</option>
                                                        <option value="501-1000">Between 501 to 1000</option>
                                                        <option value="1000+">More than 1000</option>
                                                    </select>
                                                    {errors.no_of_employee && <div className="invalid-feedback">{errors.no_of_employee[0]}</div>}
                                                </div>
                                                <div className="row g-3">
                                                    <h1 className="mb-1 h2 fw-bold">Contact Information</h1>
                                                    <hr />
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label"> First Name <span className="text-danger">*</span> </label>
                                                    <input type="text" className={`form-control ${errors.first_name ? 'is-invalid' : ''}`} id="first_name" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange} required />
                                                    {errors.first_name && <div className="invalid-feedback">{errors.first_name[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label"> Last Name <span className="text-danger">*</span> </label>
                                                    <input type="text" className={`form-control ${errors.last_name ? 'is-invalid' : ''}`} id="last_name" name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleChange} required />
                                                    {errors.last_name && <div className="invalid-feedback">{errors.last_name[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label"> Phone <span className="text-danger">*</span> </label>
                                                    <input type="text" className={`form-control ${errors.phone ? 'is-invalid' : ''}`} id="phone" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                                                    {errors.phone && <div className="invalid-feedback">{errors.phone[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">  Fax  </label>
                                                    <input type="text" className={`form-control ${errors.fax ? 'is-invalid' : ''}`} id="fax" name="fax" placeholder="Fax" value={formData.fax} onChange={handleChange} />
                                                    {errors.fax && <div className="invalid-feedback">{errors.fax[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Email <span className="text-danger">*</span> </label>
                                                    <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required />
                                                    {errors.email && <div className="invalid-feedback">{errors.email[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                                    <input type="password" className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                                                    {errors.password && <div className="invalid-feedback">{errors.password[0]}</div>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <div className="row g-3">
                                                <h1 className="mb-1 h2 fw-bold">Shipping Address</h1>
                                                <hr />
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <label className="form-label">Shipping Address 1<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_address_line1 ? 'is-invalid' : ''}`} id="s_address_line1" name="s_address_line1" placeholder="Address Line 1"   maxLength={maxLength} value={formData.s_address_line1} onChange={handleChange} required />
                                                    {errors.s_address_line1 && <div className="invalid-feedback">{errors.s_address_line1[0]}</div>}
                                                     {formData.s_address_line1.length === maxLength && (
                                                            <div className="text-danger">Maximum length of characters reached.</div>
                                                        )}
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">Shipping Address 2 <span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_address_line2 ? 'is-invalid' : ''}`} id="s_address_line2" name="s_address_line2" placeholder="Address Line 2"   value={formData.s_address_line2} onChange={handleChange} />
                                                    {errors.s_address_line2 && <div className="invalid-feedback">{errors.s_address_line2[0]}</div>}
                                                
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">City <span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_city ? 'is-invalid' : ''}`} id="s_city" name="s_city" placeholder="City" value={formData.s_city} onChange={handleChange} required />
                                                    {errors.s_city && <div className="invalid-feedback">{errors.s_city[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">State<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_state ? 'is-invalid' : ''}`} id="s_state" name="s_state" placeholder="State" value={formData.s_state} onChange={handleChange} required />
                                                    {errors.s_state && <div className="invalid-feedback">{errors.s_state[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Postal Code<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_pincode ? 'is-invalid' : ''}`} id="s_pincode" name="s_pincode" placeholder="Pincode" value={formData.s_pincode} onChange={handleChange} required />
                                                    {errors.s_pincode && <div className="invalid-feedback">{errors.s_pincode[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Country<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.s_country ? 'is-invalid' : ''}`} id="s_country" name="s_country" placeholder="Country" value={formData.s_country} onChange={handleChange} required />
                                                    {errors.s_country && <div className="invalid-feedback">{errors.s_country[0]}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <div className="row g-3">
                                                <h1 className="mb-1 h2 fw-bold">Billing Address</h1>
                                                <hr />
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <label className="form-label">Billing Address 2 <span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_address_line1 ? 'is-invalid' : ''}`} id="b_address_line1" name="b_address_line1" placeholder="Address Line 1"   maxLength={maxLength} value={formData.b_address_line1} onChange={handleChange} required />
                                                    {errors.b_address_line1 && <div className="invalid-feedback">{errors.b_address_line1[0]}</div>}
                                                    {formData.b_address_line1.length === maxLength && (
                                                        <div className="text-danger">Maximum length of characters reached.</div>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <label className="form-label">Billing Address 2 <span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_address_line2 ? 'is-invalid' : ''}`} id="b_address_line2" name="b_address_line2" placeholder="Address Line 2"   value={formData.b_address_line2} onChange={handleChange} />
                                                    {errors.b_address_line2 && <div className="invalid-feedback">{errors.b_address_line2[0]}</div>}
                                                   
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">City <span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_city ? 'is-invalid' : ''}`} id="b_city" name="b_city" placeholder="City" value={formData.b_city} onChange={handleChange} required />
                                                    {errors.b_city && <div className="invalid-feedback">{errors.b_city[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">State<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_state ? 'is-invalid' : ''}`} id="b_state" name="b_state" placeholder="State" value={formData.b_state} onChange={handleChange} required />
                                                    {errors.b_state && <div className="invalid-feedback">{errors.b_state[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Postal Code<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_pincode ? 'is-invalid' : ''}`} id="b_pincode" name="b_pincode" placeholder="Pincode" value={formData.b_pincode} onChange={handleChange} required />
                                                    {errors.b_pincode && <div className="invalid-feedback">{errors.b_pincode[0]}</div>}
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <label className="form-label">Country<span className="text-danger">*</span></label>
                                                    <input type="text" className={`form-control ${errors.b_country ? 'is-invalid' : ''}`} id="b_country" name="b_country" placeholder="Country" value={formData.b_country} onChange={handleChange} required />
                                                    {errors.b_country && <div className="invalid-feedback">{errors.b_country[0]}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-grid mt-5">
                                        <hr />
                                        <div className='row'>
                                            <div className='col-lg-12 d-grid'>
                                                <button type="submit" className="btn btn-primary">Register</button>
                                            </div>
                                            <div className='mt-3 text-center'>
                                                Already Have Account? <a href="/signin">Sign IN</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <ToastContainer />
        </>
    );
}
